import { Controller } from "@hotwired/stimulus"
import TomSelect from "tom-select";

// Connects to data-controller="tag-select-create"
export default class extends Controller {

  connect() {
    if (this.element.classList.contains('tomselected')) return;

    var options = JSON.parse(this.element.dataset["options"])
    var items   = JSON.parse(this.element.dataset["selected"])

    new TomSelect(this.element, {
      valueField: 'id',
      labelField: 'name',
      searchField: 'name',
      placeholder: "Search for a tag or create a new one...",
      create: true,
      options: options,
      items: items,
      plugins: {
        remove_button: {
          title:'Remove this item',
        }
      },
      load: function(query, callback) {
        var url = '/app/search/tags?q=' + encodeURIComponent(query);
        fetch(url)
          .then(response => response.json())
          .then(json => {
            callback(json.hits);
          }).catch(()=>{
            callback();
          });
      },
      onItemAdd:function(){
        this.setTextboxValue();
        this.refreshOptions();
      }
    });
  }

}
