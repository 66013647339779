import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["modal"]

  // Shows the background
  showBackground() {
    var element = document.getElementById("modal-backdrop");
    element.classList.remove("d-none");
    element.classList.add("d-block");
  }


  // hide modal
  // action: "modal#hideModal"
  hideModal() {
    this.element.parentElement.removeAttribute("src")

    var element = document.getElementById("modal-backdrop");
    element.classList.remove("d-block");
    element.classList.add("d-none");

    // console.log(this.modalTarget);

    // Remove src reference from parent frame element
    // Without this, turbo won't re-open the modal on subsequent click
    this.modalTarget.remove()
  }

  // hide modal when clicking ESC
  // action: "keyup@window->modal#closeWithKeyboard"
  closeWithKeyboard(e) {
    if (e.code == "Escape") {
      this.hideModal()
    }
  }

  // hide modal when clicking outside of modal
  // action: "click@window->modal#closeBackground"
  closeBackground(e) {
    if (e && this.modalTarget.contains(e.target)) {
      return
    }
    this.hideModal()
  }

}
